import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { useSelector, useDispatch } from 'react-redux';
import { YEAR_MONTH_DAY } from '../../../common/constants';
import ProjectSelect from '../../../common/selectors/Project';
import Text from '../../../common/components/base/Text';
import Button from '../../../common/components/Button';
import Stack from '../../../common/components/primitives/Stack';
import InputDate from '../../../common/components/InputDate';
import Modal from '../../../components/dialogs/Modal';
import FormItem from '../../../common/components/FormItem';
import settings from '../../../common/settings';
import { slugify } from '../../../common/utils/formatting';
import { notifySuccess } from '../../../utils/notify';
import { apiZedocOneProject } from '../../../common/api/zedoc';
import { encode } from '../../../common/utils/querystring';
import { urlJoin } from '../../../common/utils/url';
import { closeDownloadResponsesCSVDialog } from '../actions';
import { getDownloadResponsesCSVDialogVisible } from '../selectors';
import {
  setCookie,
  deleteCookie,
} from '../../../common/utilsClient/ddp/cookie';

const CreateActivity = ({ projectId }) => {
  useDDPSubscription(
    apiZedocOneProject.withParams({
      projectId,
    }),
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const isVisible = useSelector(getDownloadResponsesCSVDialogVisible);
  const [dateStart, setDateStart] = useState(
    moment().subtract(90, 'days').format(YEAR_MONTH_DAY),
  );
  const [dateEnd, setDateEnd] = useState('');
  const urlRef = useRef();

  const isStartDateAfterEndDate = useMemo(
    () => moment(dateStart).isAfter(dateEnd),
    [dateStart, dateEnd],
  );
  const isEndDateBeforeStartDate = useMemo(
    () => moment(dateEnd).isBefore(dateStart),
    [dateStart, dateEnd],
  );

  const handleOnCancel = () => dispatch(closeDownloadResponsesCSVDialog());
  const handleOnConfirm = () => {
    if (isEndDateBeforeStartDate || isStartDateAfterEndDate) {
      return;
    }

    const timezone = project && project.getTimezone();
    const query = {};

    if (dateStart) {
      query.dateStart = dateStart;
    }

    if (dateEnd) {
      query.dateEnd = dateEnd;
    }

    if (timezone) {
      query.timezone = timezone;
    }

    const { backendUrl } = settings.public;

    const path = `/integrations/responses/projects/${project._id}`;
    const expires = new Date();
    // Cookie expires in 1 minute from now
    expires.setTime(expires.getTime() + 60 * 1000);
    dispatch(setCookie(path, expires.toUTCString()));

    const url = `${urlJoin(backendUrl, path)}${encode(query)}`;

    urlRef.current.href = url;
    urlRef.current.download = slugify(project.getName());
    urlRef.current.click();

    setTimeout(() => {
      dispatch(deleteCookie(path));
      notifySuccess(t('confirmations:downloadResponsesCSV.success'))();
    }, 1000);

    handleOnCancel();
  };

  return (
    <Modal
      title={t('confirmations:downloadResponsesCSV.title')}
      tooltip={t('confirmations:downloadResponsesCSV.tooltip')}
      onCancel={handleOnCancel}
      footer={
        <>
          <Button
            data-testid="button-ok"
            type="primary"
            disabled={isEndDateBeforeStartDate || isStartDateAfterEndDate}
            onClick={handleOnConfirm}
          >
            {t('confirmAndDownload')}
          </Button>
        </>
      }
      visible={isVisible}
    >
      <a ref={urlRef} href="/" className="hidden" hidden="true">
        &nbsp;
      </a>
      <Stack>
        <FormItem
          label={t('forms:dateStart.label')}
          help={
            isEndDateBeforeStartDate &&
            t('forms:dateStart.errorCannotBeAfterEndDate')
          }
          validateStatus="danger"
        >
          <InputDate
            data-testid="form-field-start-date"
            onChange={setDateStart}
            value={dateStart}
            max={dateEnd}
          />
        </FormItem>
        <FormItem
          label={t('forms:dateEnd.label')}
          help={
            isStartDateAfterEndDate &&
            t('forms:dateEnd.errorCannotBeBeforeStartDate')
          }
          validateStatus="danger"
        >
          <InputDate
            data-testid="form-field-end-date"
            onChange={setDateEnd}
            value={dateEnd}
            min={dateStart}
          />
        </FormItem>
        {project && (
          <Text.Paragraph>
            * {project.timezone} {t('forms:timezone.label')}
          </Text.Paragraph>
        )}
      </Stack>
    </Modal>
  );
};

CreateActivity.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default CreateActivity;
