// @ts-check
import some from 'lodash/some';
import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import { isPrefix } from './text';
import { PERMISSIONS_DOMAIN_DELIMITER } from '../constants';

/**
 * @typedef {object} Ownership
 * @property {string} domain
 */

/**
 * @param {string | Array<Ownership | string>} domains
 * @returns {Ownership[]}
 */
export const toOwnership = (domains) => {
  if (typeof domains === 'string') {
    return [
      {
        domain: domains,
      },
    ];
  }
  if (isArray(domains)) {
    return map(domains, (domain) => {
      if (typeof domain === 'string') {
        return {
          domain,
        };
      }
      return domain;
    });
  }
  return [];
};

/**
 * Return new list of domains based on "newOwnership", but take into account what
 * can be created and what can be deleted. For example if user does not have
 * permission to delete anything (deleteRealm = []), only new domains can be
 * added, but none of the old domains can be removed.
 * @param {string[] | Ownership[]} newOwnershipOrDomains
 * @param {string[] | Ownership[]} oldOwnershipOrDomains
 * @param {string[]} createRealm
 * @param {string[]} deleteRealm
 */
function getUpdatedOwnership(
  newOwnershipOrDomains = [],
  oldOwnershipOrDomains = [],
  createRealm,
  deleteRealm,
) {
  const newOwnership = toOwnership(newOwnershipOrDomains);
  const oldOwnership = toOwnership(oldOwnershipOrDomains);

  const newOwnershipByDomain = keyBy(newOwnership, 'domain');
  const oldOwnershipByDomain = keyBy(oldOwnership, 'domain');

  /**
   * @param {string} domain
   */
  const domainExistsInNewOwnership = (domain) => !!newOwnershipByDomain[domain];
  /**
   * @param {string} domain
   */
  const domainExistsInOldOwnership = (domain) => !!oldOwnershipByDomain[domain];

  const createPredicates = map(createRealm, (domain) =>
    isPrefix(domain, PERMISSIONS_DOMAIN_DELIMITER),
  );
  const deletePredicates = map(deleteRealm, (domain) =>
    isPrefix(domain, PERMISSIONS_DOMAIN_DELIMITER),
  );

  /**
   * @param {string} domain
   */
  const canCreate = (domain) =>
    some(createPredicates, (predicate) => predicate(domain));
  /**
   * @param {string} domain
   */
  const canDelete = (domain) =>
    some(deletePredicates, (predicate) => predicate(domain));

  /** @type {Ownership[]} */
  const updatedOwnership = [];

  forEach(oldOwnership, (owner) => {
    if (!canDelete(owner.domain)) {
      updatedOwnership.push(owner);
    } else if (domainExistsInNewOwnership(owner.domain)) {
      updatedOwnership.push(newOwnershipByDomain[owner.domain]);
    }
  });

  forEach(newOwnership, (owner) => {
    if (canCreate(owner.domain) && !domainExistsInOldOwnership(owner.domain)) {
      updatedOwnership.push(owner);
    }
  });

  return updatedOwnership;
}

export default getUpdatedOwnership;
