import settings from '../../settings';
import { getResumeToken } from './selectors';

let forceSecure = true;
if (process.env.NODE_ENV !== 'production') {
  forceSecure = false;
}

const AUTH_COOKIE_NAME = 'X-Auth-Token';
const domain = settings.public && settings.public.rootDomain;

const serialize = (token, secure, path = '/') => `\
${AUTH_COOKIE_NAME}=${token || ''}\
;path=${path}\
${secure ? ';secure' : ''}\
${domain ? `;domain=.${domain}` : ''}`;

const assignCookie = (token, path = '/', expires) => {
  if (token) {
    document.cookie = !expires
      ? serialize(token, forceSecure, path)
      : `${serialize(token, forceSecure, path)};expires=${expires}`;
  } else {
    // NOTE: If token is absent, it means we will be deleting cookie instead.
    document.cookie = `${serialize(
      token,
      forceSecure,
      path,
    )};expires=${new Date(0).toUTCString()}`;
  }
};

export const setCookie = (path, expires) => (dispatch, getState) => {
  const token = getResumeToken(getState());
  assignCookie(token, path, expires);
  dispatch({
    type: 'SET_COOKIE',
    payload: token,
    meta: {
      name: AUTH_COOKIE_NAME,
    },
  });
};

export const deleteCookie = (path) => (dispatch) => {
  assignCookie(null, path);
  dispatch({
    type: 'DELETE_COOKIE',
    meta: {
      name: AUTH_COOKIE_NAME,
    },
  });
};

export const createCookieManager = (store) => {
  let previousToken = getResumeToken(store.getState());
  let unsubscribe = store.subscribe(() => {
    const token = getResumeToken(store.getState());
    if (token !== previousToken) {
      assignCookie(token);
      previousToken = token;
    }
  });
  assignCookie(previousToken);
  return {
    cleanCookie() {
      assignCookie();
    },
    stop: () => {
      if (unsubscribe) {
        unsubscribe();
        unsubscribe = null;
      }
      previousToken = null;
      // ensure cookie is deleted ...
      assignCookie();
    },
  };
};
