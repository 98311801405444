import ApiSpec from './ApiSpec';
import {
  ADMIN_SEARCH_PROJECT,
  ADMIN_CREATE_PROJECT,
  ADMIN_DELETE_PROJECT,
  ADMIN_UPDATE_PROJECT,
  ADMIN_REVIEW_PROJECT,
  ADMIN_SEARCH_USER,
  ADMIN_REVIEW_USER,
  ADMIN_SEARCH_AUDIT_LOGS,
  ADMIN_UPDATE_VARIABLE,
  ADMIN_REVIEW_GROUP,
  ADMIN_SEARCH_GROUP,
  ADMIN_CREATE_GROUP,
  ADMIN_UPDATE_GROUP,
  ADMIN_SEARCH_ROLE,
  ADMIN_REVIEW_ROLE,
  ADMIN_CREATE_ROLE,
  ADMIN_UPDATE_ROLE,
  ADMIN_SEARCH_VARIABLE,
  ADMIN_REVIEW_VARIABLE,
  ADMIN_CREATE_VARIABLE,
  ADMIN_DELETE_VARIABLE,
  ADMIN_SEARCH_DOMAIN,
  ADMIN_REVIEW_DOMAIN,
  ADMIN_CREATE_DOMAIN,
  ADMIN_UPDATE_DOMAIN,
  ORGANIZATION_UPDATE_QUESTIONNAIRE,
} from '../permissions';
import { searchApiSchema } from './common';
import { SCOPE_NAMES } from '../constants';

export const ProjectProperties = {
  name: {
    type: 'string',
  },
  billingCode: {
    type: 'string',
  },
  description: {
    type: 'string',
  },
  logoUrl: {
    type: 'string',
  },
  timezone: {
    type: 'string',
  },
  dateStart: {
    type: 'string',
    format: 'date',
  },
  dateEnd: {
    type: 'string',
    format: 'date',
  },
  fallbackLanguage: {
    type: 'string',
  },
  otherSupportedLanguages: {
    type: 'array',
    items: {
      type: 'string',
    },
  },
  questionnaires: {
    type: 'array',
    items: {
      type: 'object',
      required: ['identifier', 'version'],
      properties: {
        identifier: {
          type: 'string',
        },
        version: {
          type: 'string',
        },
        namespace: {
          type: 'string',
        },
        initialBindings: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              variableId: {
                type: 'string',
              },
              questionnaireVariableId: {
                type: 'string',
              },
            },
          },
        },
        finalBindings: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              variableId: {
                type: 'string',
              },
              questionnaireVariableId: {
                type: 'string',
              },
            },
          },
        },
      },
    },
  },
  variables: {
    type: 'array',
    items: {
      type: 'object',
      required: ['id'],
      properties: {
        id: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        compulsory: {
          type: 'boolean',
        },
        csv: {
          type: 'boolean',
        },
      },
    },
  },
  csvSchemata: {
    type: 'array',
    items: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        columns: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              header: {
                type: 'string',
              },
              bindingType: {
                type: 'string',
              },
              variableId: {
                type: 'string',
              },
              isUniqueIdentifier: {
                type: 'boolean',
              },
              uniqueIdentifierScope: {
                type: 'string',
              },
              uniqueIdentifierScopeName: {
                type: 'string',
              },
            },
          },
        },
      },
    },
  },
  trackNameVariableId: {
    type: 'string',
  },
  tracks: {
    type: 'array',
    items: {
      type: 'object',
      required: ['name'],
      properties: {
        id: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        description: {
          type: 'string',
        },
      },
    },
  },
  milestones: {
    type: 'array',
    items: {
      type: 'object',
      required: ['name'],
      properties: {
        id: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        type: {
          type: 'string',
        },
        description: {
          type: 'string',
        },
        plusDays: {
          type: 'integer',
        },
        minusDays: {
          type: 'integer',
        },
        timeStart: {
          type: 'string',
        },
        timeEnd: {
          type: 'string',
        },
        daysSinceBaseline: {
          type: 'integer',
        },
        questionnaires: {
          type: 'array',
          items: {
            type: 'object',
            required: ['identifier', 'assigneeType'],
            properties: {
              identifier: {
                type: 'string',
              },
              assigneeType: {
                type: 'string',
              },
              delegatedToPatient: {
                type: 'boolean',
              },
            },
          },
        },
        dependencies: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              milestoneId: {
                type: 'string',
              },
              assigneeTypes: {
                type: 'array',
                items: {
                  type: 'string',
                },
              },
            },
          },
        },
        delayDays: {
          type: 'number',
        },
        recurring: {
          type: 'boolean',
        },
        maxRepetitions: {
          type: 'integer',
        },
        repeatAfterDays: {
          type: 'integer',
        },
        selectedTracksOnly: {
          type: 'boolean',
        },
        selectedTracks: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
    },
  },
  notifications: {
    type: 'array',
    items: {
      type: 'object',
      required: ['type', 'delivery'],
      properties: {
        id: {
          type: 'string',
        },
        type: {
          type: 'string',
        },
        eventType: {
          type: 'string',
        },
        group: {
          type: 'string',
        },
        delivery: {
          type: 'string',
        },
        destinations: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              type: {
                type: 'string',
              },
              address: {
                type: 'string',
              },
            },
          },
        },
        templates: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        selectedMilestonesOnly: {
          type: 'boolean',
        },
        selectedMilestones: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        selectedTracksOnly: {
          type: 'boolean',
        },
        selectedTracks: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        delayMinutes: {
          type: 'integer',
        },
        delayDays: {
          type: 'integer',
        },
        assigneeTypes: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
    },
  },
  messageTemplateBindings: {
    type: 'array',
    items: {
      type: 'object',
      properties: {
        templateVariableId: {
          type: 'string',
        },
        variableId: {
          type: 'string',
        },
      },
    },
  },
  messageTemplates: {
    type: 'array',
    items: {
      type: 'object',
      required: ['name', 'purpose', 'content'],
      properties: {
        id: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        purpose: {
          type: 'string',
        },
        language: {
          type: 'string',
        },
        subject: {
          type: 'string',
        },
        content: {
          type: 'string',
        },
        htmlContent: {
          type: 'string',
        },
      },
    },
  },
  eappRules: {
    type: 'array',
    items: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        description: {
          type: 'string',
        },
        priority: {
          type: 'string',
          // enum: [
          //   'low',
          //   'medium',
          //   'high',
          // ],
        },
        triggerType: {
          type: 'string',
        },
        assigneeTypes: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        selectedMilestonesOnly: {
          type: 'boolean',
        },
        selectedMilestones: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        selectedQuestionnairesOnly: {
          type: 'boolean',
        },
        selectedQuestionnaires: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        variables: {
          type: 'string',
        },
        condition: {
          type: 'string',
        },
        actionsToTrigger: {
          type: 'array',
          items: {
            type: 'object',
            required: ['id', 'type'],
            properties: {
              id: {
                type: 'string',
              },
              type: {
                type: 'string',
              },
              useVariables: {
                type: 'boolean',
              },
              variables: {
                type: 'string',
              },
              milestoneId: {
                type: 'string',
              },
              notificationId: {
                type: 'string',
              },
              trackId: {
                type: 'string',
              },
            },
          },
        },
      },
    },
  },
  dashboards: {
    type: 'array',
    items: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
        },
        title: {
          type: 'string',
        },
        type: {
          type: 'string',
          // enum: [
          //   'actionItems',
          //   'general',
          //   'patient',
          //   'CAT',
          //   'NLP',
          //   'chartBuilder',
          //   'externalIFrame',
          //   'milestonesTable',
          // ],
        },
        perspective: {
          type: 'string',
        },
        settings: {
          type: 'string',
        },
        theme: {
          type: 'string',
          // enum: [
          //   'primary',
          //   'accent',
          // ],
        },
        columns: {
          type: 'array',
          items: {
            type: 'object',
            required: ['id', 'title', 'formula'],
            properties: {
              id: {
                type: 'string',
              },
              title: {
                type: 'string',
              },
              formula: {
                type: 'string',
              },
              align: {
                type: 'string', // left | right | center
              },
              status: {
                type: 'string', // formula evaluating to one of: warning | danger | success
              },
            },
          },
        },
        variables: {
          type: 'string',
        },
        charts: {
          type: 'array',
          items: {
            type: 'object',
            required: ['id', 'title', 'type'],
            properties: {
              id: {
                type: 'string',
              },
              title: {
                type: 'string',
              },
              type: {
                type: 'string',
              },
              settings: {
                type: 'string',
              },
              dataProvider: {
                type: 'string',
              },
              dataProviderCleanup: {
                type: 'string',
              },
              detailedViewTransformation: {
                type: 'string',
              },
            },
          },
        },
        cards: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              id: {
                type: 'string',
              },
              title: {
                type: 'string',
              },
              valueAxisTitle: {
                type: 'string',
              },
              valueAxisMinimum: {
                type: 'number',
              },
              valueAxisMaximum: {
                type: 'number',
              },
              chartPrecision: {
                type: 'number',
              },
              chartGuides: {
                type: 'string',
              },
              chartType: {
                type: 'string',
                // enum: [
                //   'totalScore',
                //   'totalScoreIndividual',
                //   'totalScoreNoMilestones',
                //   'totalScoreNoMilestonesIndividual',
                //   'propertyBreakdown',
                //   'propertyBreakdown2',
                //   'propertyBreakdownIndividual',
                //   'propertyBreakdown2Individual',
                //   'propertyFiveValuesBoxPlot',
                //   'propertyHistogram',
                //   'propertyHistogramIndividual',
                //   'questionBreakdown',
                //   'fiveValuesBoxPlot',
                //   'averageValue',
                //   'topAverageValues',
                //   'averageValues',
                //   'radarAverageScoreAndVariable',
                //   'answersSheetStateByDateScheduled',
                //   'averageValueAgainstMilestone',
                //   'averageValueAgainstAnotherValue',
                //   'valueChoicesAgainstMilestone',
                //   'valueChoicesAgainstAnotherValue',
                //   'mostRecentNumericValuePieChart',
                //   'mostRecentValuePieChart',
                // ],
              },
              chartSettings: {
                type: 'string',
              },
              chartSize: {
                type: 'string',
                // enum: [
                //   'small',
                //   'medium',
                //   'large',
                //   'xlarge',
                //   'xtall',
                // ],
              },
              chartVariants: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    value: {
                      type: 'string',
                    },
                    label: {
                      type: 'string',
                    },
                    params: {
                      type: 'string',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

export const apiAdminSearchProjects = new ApiSpec({
  name: 'api.admin.searchProjects',
  permissions: [ADMIN_SEARCH_PROJECT],
  schema: searchApiSchema,
});

export const apiAdminGetProjectProperties = new ApiSpec({
  name: 'api.admin.getProjectProperties',
  permissions: [ADMIN_REVIEW_PROJECT],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      projectId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminCreateProject = new ApiSpec({
  name: 'api.admin.createProject',
  permissions: [ADMIN_CREATE_PROJECT],
  schema: {
    type: 'object',
    properties: {
      ...ProjectProperties,
      belongsTo: {
        type: 'string',
      },
    },
  },
});

export const apiAdminUpdateProject = new ApiSpec({
  name: 'api.admin.updateProject',
  permissions: [ADMIN_UPDATE_PROJECT],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      ...ProjectProperties,
      projectId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminArchiveProject = new ApiSpec({
  name: 'api.admin.archiveProject',
  permissions: [ADMIN_DELETE_PROJECT],
  schema: {
    type: 'object',
    required: ['projectId'],
    properties: {
      projectId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminSearchAuditLogs = new ApiSpec({
  name: 'api.admin.searchAuditLogs',
  permissions: [ADMIN_SEARCH_AUDIT_LOGS],
  schema: searchApiSchema,
});

export const apiAdminSearchUsers = new ApiSpec({
  name: 'api.admin.searchUsers',
  permissions: [ADMIN_SEARCH_USER],
  schema: searchApiSchema,
});

export const apiAdminRefreshQuestionnairesRepository = new ApiSpec({
  name: 'api.admin.refreshQuestionnairesRepository',
  permissions: [ORGANIZATION_UPDATE_QUESTIONNAIRE],
  schema: {
    type: 'object',
    properties: {
      namespace: {
        type: 'string',
      },
      identifier: {
        type: 'string',
      },
    },
  },
});

export const apiAdminOneUser = new ApiSpec({
  name: 'api.admin.oneUser',
  permissions: [ADMIN_REVIEW_USER],
  schema: {
    type: 'object',
    required: ['userId'],
    properties: {
      userId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminSearchPermissionsDomains = new ApiSpec({
  name: 'api.admin.searchPermissionsDomains',
  permissions: [ADMIN_SEARCH_DOMAIN],
  schema: searchApiSchema,
});

export const apiAdminOnePermissionsDomain = new ApiSpec({
  name: 'api.admin.onePermissionsDomain',
  permissions: [ADMIN_REVIEW_DOMAIN],
  schema: {
    type: 'object',
    required: ['domainId'],
    properties: {
      domainId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminSearchUsersGroups = new ApiSpec({
  name: 'api.admin.searchUsersGroups',
  permissions: [ADMIN_SEARCH_GROUP],
  schema: searchApiSchema,
});

export const apiAdminOneUsersGroup = new ApiSpec({
  name: 'api.admin.oneUsersGroup',
  permissions: [ADMIN_REVIEW_GROUP],
  schema: {
    type: 'object',
    required: ['groupId'],
    properties: {
      groupId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminSearchRoles = new ApiSpec({
  name: 'api.admin.searchRoles',
  permissions: [ADMIN_SEARCH_ROLE],
  schema: searchApiSchema,
});

export const apiAdminOneRole = new ApiSpec({
  name: 'api.admin.oneRole',
  permissions: [ADMIN_REVIEW_ROLE],
  schema: {
    type: 'object',
    required: ['roleId'],
    properties: {
      roleId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminSearchVariables = new ApiSpec({
  name: 'api.admin.searchVariables',
  permissions: [ADMIN_SEARCH_VARIABLE],
  schema: searchApiSchema,
});

export const apiAdminOneVariable = new ApiSpec({
  name: 'api.admin.oneVariable',
  resources: [ADMIN_REVIEW_VARIABLE],
  schema: {
    type: 'object',
    required: ['variableId'],
    properties: {
      variableId: {
        type: 'string',
      },
    },
  },
});

export const apiAdminUpdateRole = new ApiSpec({
  name: 'api.admin.updateRole',
  permissions: [ADMIN_UPDATE_ROLE],
  schema: {
    type: 'object',
    required: ['roleId'],
    properties: {
      roleId: {
        type: 'string',
      },
      name: {
        type: 'string',
      },
      belongsTo: {
        type: 'string',
      },
      permissions: {
        type: 'array',
        items: {
          type: 'object',
          required: ['key'],
          properties: {
            key: {
              type: 'string',
            },
          },
        },
      },
    },
  },
});

export const apiAdminCreateRole = new ApiSpec({
  name: 'api.admin.createRole',
  permissions: [ADMIN_CREATE_ROLE],
  schema: {
    type: 'object',
    required: ['name', 'belongsTo'],
    properties: {
      name: {
        type: 'string',
      },
      belongsTo: {
        type: 'string',
      },
      permissions: {
        type: 'array',
        items: {
          type: 'object',
          required: ['key'],
          properties: {
            key: {
              type: 'string',
            },
          },
        },
      },
    },
  },
});

export const apiAdminCreatePermissionsDomain = new ApiSpec({
  name: 'api.admin.createPermissionsDomain',
  permissions: [ADMIN_CREATE_DOMAIN],
  schema: {
    type: 'object',
    required: ['id', 'name'],
    properties: {
      name: {
        type: 'string',
      },
      id: {
        type: 'string',
      },
    },
  },
});

export const apiAdminUpdatePermissionsDomain = new ApiSpec({
  name: 'api.admin.updatePermissionsDomain',
  permissions: [ADMIN_UPDATE_DOMAIN],
  schema: {
    type: 'object',
    required: ['id', 'name'],
    properties: {
      name: {
        type: 'string',
      },
      id: {
        type: 'string',
      },
    },
  },
});

export const apiAdminCreateUsersGroup = new ApiSpec({
  name: 'api.admin.apiAdminCreateUsersGroup',
  permissions: [ADMIN_CREATE_GROUP],
  schema: {
    type: 'object',
    required: ['name', 'type', 'roles', 'belongsTo'],
    properties: {
      name: {
        type: 'string',
      },
      type: {
        type: 'string',
      },
      belongsTo: {
        type: 'string',
      },
      roles: {
        type: 'array',
        items: {
          type: 'object',
          required: ['id', 'tier', 'name'],
          properties: {
            id: {
              type: 'string',
            },
            tier: {
              type: 'number',
            },
            name: {
              type: 'string',
            },
          },
        },
      },
    },
  },
});

export const apiAdminUpdateUsersGroup = new ApiSpec({
  name: 'api.admin.apiAdminUpdateUsersGroup',
  permissions: [ADMIN_UPDATE_GROUP],
  schema: {
    type: 'object',
    required: ['groupId', 'name', 'type', 'roles', 'belongsTo'],
    properties: {
      groupId: {
        type: 'string',
      },
      name: {
        type: 'string',
      },
      type: {
        type: 'string',
      },
      belongsTo: {
        type: 'string',
      },
      roles: {
        type: 'array',
        items: {
          type: 'object',
          required: ['id', 'tier', 'name'],
          properties: {
            id: {
              type: 'string',
            },
            tier: {
              type: 'number',
            },
            name: {
              type: 'string',
            },
          },
        },
      },
    },
  },
});

export const apiAdminCreateVariable = new ApiSpec({
  name: 'api.admin.apiAdminCreateVariable',
  resources: [
    {
      action: ADMIN_CREATE_VARIABLE,
      inAllOf: 'domains',
      creating: true,
    },
  ],
  schema: {
    type: 'object',
    required: ['inputType', 'name', 'scopeName', 'domains', 'scopeName'],
    properties: {
      inputType: {
        enum: ['custom', 'identifier'],
      },
      jsonSchema: {
        type: 'string',
      },
      name: {
        type: 'string',
      },
      scopeName: {
        type: 'string',
        enum: SCOPE_NAMES,
      },
      idNamespace: {
        type: 'string',
      },
      pii: {
        type: 'boolean',
      },
      domains: {
        type: 'array',
        items: {
          type: 'string',
        },
      },
    },
  },
});

export const apiAdminUpdateVariable = new ApiSpec({
  name: 'api.admin.apiAdminUpdateVariable',
  resources: [
    ADMIN_UPDATE_VARIABLE,
    {
      variables: {
        canCreateIn: ADMIN_CREATE_VARIABLE,
        canDeleteIn: ADMIN_DELETE_VARIABLE,
      },
    },
  ],
  schema: {
    type: 'object',
    required: ['variableId'],
    properties: {
      variableId: {
        type: 'string',
      },
      jsonSchema: {
        type: 'string',
      },
      name: {
        type: 'string',
      },
      domains: {
        type: 'array',
        items: {
          type: 'string',
        },
      },
    },
  },
});
