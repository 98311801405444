import styled from 'styled-components';
import { QuestionCircle } from 'styled-icons/fa-regular';
import { ExclamationCircle } from 'styled-icons/heroicons-outline';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Stack from '../primitives/Stack';
import Cluster from '../primitives/Cluster';
import Text from '../base/Text';
import Spacer from '../primitives/Spacer';
import Tooltip from '../Tooltip';
import { theme } from '../../utilsClient/cssHelpers';

const validateStatusOptions = ['default', 'danger', 'success', 'warning'];

const StyledRequired = styled(Text.Span)`
  ${(props) =>
    props.required
      ? 'opacity: 1;'
      : `
    opacity: 0;
  `};
`;

const StyledExclamationCircle = styled(ExclamationCircle)`
  margin-inline-end: ${theme('space.1')};
`;

const WrapperLabel = ({ children, htmlFor }) => {
  return htmlFor ? (
    <Text.Label htmlFor={htmlFor}>{children}</Text.Label>
  ) : (
    children
  );
};

WrapperLabel.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string,
};

WrapperLabel.defaultProps = {
  htmlFor: null,
};

const FormItem = ({
  'data-testid': testId,
  children,
  htmlFor,
  label,
  required,
  validateStatus,
  help,
  extra,
  tooltip,
  colon,
  size,
  isFullHeight,
}) => {
  const getHelpIcon = useCallback(() => {
    switch (validateStatus) {
      case 'danger':
        return <StyledExclamationCircle />;
      default:
        return null;
    }
  }, [validateStatus]);

  return (
    <Stack space={1} isFullHeight={isFullHeight}>
      <WrapperLabel htmlFor={htmlFor}>
        <Stack space={1} isFullHeight={isFullHeight}>
          {(label || tooltip) && (
            <Cluster justify="space-between">
              <Text.Paragraph>
                <Text.Span size={size} importance="high">
                  {label}
                </Text.Span>
                {colon && <Text.Span>:</Text.Span>}{' '}
                {/* TODO: We can just remove it / add it to the DOM instead of hiding */}
                <StyledRequired
                  data-testid={`${testId}-asterisk`}
                  type="danger"
                  size="small"
                  required={required}
                >
                  *
                </StyledRequired>
              </Text.Paragraph>
              {tooltip && (
                <Tooltip title={tooltip}>
                  <QuestionCircle />
                </Tooltip>
              )}
            </Cluster>
          )}
          {children}
        </Stack>
      </WrapperLabel>
      {(help || extra) && (
        <Spacer space={2}>
          <Stack space={0}>
            {help && (
              <Text.Paragraph
                data-testid={`${testId}-status`}
                type={validateStatus}
              >
                {getHelpIcon()}
                <Text.Span>{help}</Text.Span>
              </Text.Paragraph>
            )}
            {extra && (
              <Text.Paragraph size="small" importance="low">
                {extra}
              </Text.Paragraph>
            )}
          </Stack>
        </Spacer>
      )}
    </Stack>
  );
};

FormItem.propTypes = {
  'data-testid': PropTypes.string,
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string,
  label: PropTypes.node,
  required: PropTypes.bool,
  validateStatus: PropTypes.oneOf(validateStatusOptions),
  help: PropTypes.string,
  extra: PropTypes.node,
  tooltip: PropTypes.string,
  colon: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  isFullHeight: PropTypes.bool,
};

FormItem.defaultProps = {
  'data-testid': 'form-item',
  htmlFor: null,
  label: null,
  required: false,
  validateStatus: 'default',
  help: null,
  extra: null,
  tooltip: null,
  colon: false,
  size: 'default',
  isFullHeight: false,
};

export default FormItem;

export { validateStatusOptions };
