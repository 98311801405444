import isNaN from 'lodash/isNaN';
import find from 'lodash/find';
import compact from 'lodash/compact';
import every from 'lodash/every';
import keyBy from 'lodash/keyBy';
import forEach from 'lodash/forEach';
import includes from 'lodash/includes';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';
import sumBy from 'lodash/sumBy';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import {
  DEFAULT_PROJECT_FILTERS,
  DEFAULT_PROJECT_SORTING_OPTIONS,
  YEAR_MONTH_DAY,
  FILTER_TYPE__VARIABLE,
  MESSAGE_PURPOSE__GREET_PATIENT,
  ACTIVITY_STATE__COMPLETED,
  ACTIVITY_STATE__ACTIVE,
} from '../constants';
import ProjectFilter from './ProjectFilter';
import ProjectVariable from './ProjectVariable';
import BaseModel from './BaseModel';
import { zoneToUtcOffset } from '../utils/zone';
import { isMatchingVersion, splitQuestionnaireId } from '../utils/versions';
import { strToBase64Url } from '../utils/base64';
import appSettings from '../settings';
import mergeLanguagePreference from '../utils/mergeLanguagePreference';

const { defaultTimezone = 'UTC' } = appSettings.private || {};

class Project extends BaseModel {
  constructor(doc) {
    super(doc);
    this.variables = map(
      this.variables,
      (rawVariable) => new ProjectVariable(rawVariable, this),
    );
  }

  getName() {
    return this.name;
  }

  getDomains() {
    return map(this.ownership, 'domain');
  }

  getLanguagePreference(patientLanguagePreference, templatesDB = {}) {
    const supportedLanguages = [];
    if (this.fallbackLanguage) {
      supportedLanguages.push(this.fallbackLanguage);
      if (this.otherSupportedLanguages) {
        supportedLanguages.push(...this.otherSupportedLanguages);
      }
    } else {
      // NOTE: If fallbackLanguage is not explicitly specified for that project
      //       we will use the welcome message templates to deduce what the
      //       supported languages should be.
      const defaultSupportedLanguages = map(
        sortBy(
          filter(templatesDB, {
            projectId: this._id,
            purpose: MESSAGE_PURPOSE__GREET_PATIENT,
          }),
          'index',
        ),
        'language',
      );
      supportedLanguages.push(...defaultSupportedLanguages);
    }
    return mergeLanguagePreference(
      patientLanguagePreference,
      supportedLanguages,
    );
  }

  getTimezone() {
    // NOTE: The defaultTimezone is added automatically by server in project details subscription.
    return (
      this.timezone ||
      this.defaultTimezone ||
      this.constructor.getDefaultTimezone()
    );
  }

  getMomentInLocalTime(timestamp = Date.now()) {
    return this.constructor.getMomentInTimezone(this.getTimezone(), timestamp);
  }

  getCurrentYearMonthDay(timestamp) {
    return this.getMomentInLocalTime(timestamp).format(YEAR_MONTH_DAY);
  }

  applyQuestionnaireDefaults(milestoneQuestionnaires) {
    const questionnaires = [];
    const byIdentifier = keyBy(this.questionnaires, 'identifier');
    forEach(milestoneQuestionnaires, (settings) => {
      const questionnaire = byIdentifier[settings.identifier];
      if (questionnaire) {
        const { version, identifier, navigationTypes } = questionnaire;
        const defaultNavigationType = navigationTypes && navigationTypes[0];
        let { navigationType } = settings;
        if (navigationType || !includes(navigationTypes, navigationType)) {
          navigationType = defaultNavigationType;
        }
        questionnaires.push({
          ...settings,
          version,
          identifier,
          navigationTypes,
          id: `${identifier}@${version}`,
          navigationType: settings.navigationType || defaultNavigationType,
        });
      }
    });
    return questionnaires;
  }

  getRelatedVariablesIds() {
    const { variables, csvSchemata, questionnaires, messageTemplateBindings } =
      this;
    return uniq([
      ...map(variables, 'id'),
      ...map(messageTemplateBindings, 'variableId'),
      ...flatMap(csvSchemata, ({ columns }) => {
        return compact(map(columns, 'variableId'));
      }),
      ...flatMap(questionnaires, ({ finalBindings, initialBindings }) => {
        return [
          ...map(initialBindings, 'variableId'),
          ...map(finalBindings, 'variableId'),
        ];
      }),
    ]);
  }

  // TODO: We should probably get rid of this one.
  getProjectName() {
    return this.name || '';
  }

  getProjectDescription() {
    return this.description || '';
  }

  getQuestionnaireId() {
    return this.questionnaire && this.questionnaire.id;
  }

  getQuestionnaires() {
    const questionnaires = this.questionnaires || [];
    if (this.questionnaire) {
      questionnaires.push(this.questionnaire);
    }
    return questionnaires;
  }

  getQuestionnaireConfig(questionnaireId) {
    const [identifier, version] = splitQuestionnaireId(questionnaireId);
    return find(this.questionnaires, (config) => {
      return (
        config.identifier === identifier &&
        isMatchingVersion(config.version, version)
      );
    });
  }

  getDateStart() {
    return moment(
      this.dateStart && this.dateStart.yearMonthDay,
      YEAR_MONTH_DAY,
    ).toDate();
  }

  getDateEnd() {
    return moment(
      this.dateEnd && this.dateEnd.yearMonthDay,
      YEAR_MONTH_DAY,
    ).toDate();
  }

  getCurrentDay(currentDate) {
    const dateStart = this.getDateStart();
    return moment(currentDate).diff(dateStart, 'days') + 1;
  }

  getNumberOfDays() {
    const dateStart = this.getDateStart();
    const dateEnd = this.getDateEnd();
    return moment(dateEnd).diff(dateStart, 'days') + 1;
  }

  getRemainingDays() {
    return this.getNumberOfDays() - Math.max(0, this.getCurrentDay());
  }

  getRemaining() {
    if (!this.targetCount) return 0;
    return Math.max(0, this.targetCount - this.getCompleted());
  }

  getProfileFormVariables(patientRecord) {
    return this.variables.map((variable) => ({
      id: variable.id,
      value: patientRecord.getVariable(variable.id),
    }));
  }

  getSortingOptions() {
    return this.sortingPresets || DEFAULT_PROJECT_SORTING_OPTIONS;
  }

  getFilters() {
    return this.filters || DEFAULT_PROJECT_FILTERS;
  }

  isFinished() {
    return !!this.completed;
  }

  isActive() {
    return !!this.active;
  }

  isOngoing() {
    return this.getCurrentDay() > 0;
  }

  getTargetCount() {
    return this.targetCount || 0;
  }

  getCompleted() {
    return this.totalCompleted || 0;
  }

  getDeclined() {
    return this.totalDeclined || 0;
  }

  getInProgress() {
    return this.totalInProgress || 0;
  }

  getBoundFilters(filtersState = {}, { includeAllTags } = {}) {
    const filters = this.getFilters().map(
      ({ id, defaultState, type, settings, restrictedTo, tags, ...rest }) => {
        if (
          includeAllTags &&
          !every(includeAllTags, (tag) => includes(tags, tag))
        ) {
          return null;
        }
        const currentState = filtersState[id];
        return new ProjectFilter({
          ...rest,
          id,
          type,
          settings,
          tags,
          state: {
            ...defaultState,
            ...currentState,
          },
        });
      },
    );
    return compact(filters);
  }

  getSortWeights(id) {
    const projectFilter = find(
      this.getFilters(),
      (el) => el.type === FILTER_TYPE__VARIABLE && el.settings.id === id,
    );
    return projectFilter && projectFilter.sortWeights;
  }

  getPercentOfTimeElapsed(currentDate) {
    const dateStart = this.getDateStart();
    const dateEnd = this.getDateEnd();
    const totalDays = moment(dateEnd).diff(dateStart, 'days');
    const daysSinceStart = moment(currentDate).diff(dateStart, 'days');
    const timeElapsed = Math.min(1, Math.max(0, daysSinceStart / totalDays));
    return Math.floor(100 * timeElapsed);
  }

  getPercentOfCompletion() {
    if (!this.targetCount) {
      return 0;
    }
    const ratio = Math.min(1, this.getCompleted() / this.targetCount);
    return Math.floor(100 * ratio);
  }

  shouldAllowAmendment(answersSheet) {
    // TODO: Take into account when exactly the answers sheet was completed
    //       and what's the grace period for making changes.
    return (
      !!this.amendmentsAllowed &&
      answersSheet.isCompleted() &&
      (!this.amendmentsTimeoutInSeconds ||
        answersSheet.secondsSinceCompletion() < this.amendmentsTimeoutInSeconds)
    );
  }

  getReference() {
    const { _id: id, name } = this;
    return {
      id,
      name,
    };
  }

  getNumberOfMilestones() {
    return this['summary:milestones'] || 0;
  }

  getNumberOfParticipations() {
    return sumBy(this['summary:participations'], 'total');
  }

  getNumberOfCompletedActivities() {
    return sumBy(
      filter(this['summary:activities'], { state: ACTIVITY_STATE__COMPLETED }),
      'total',
    );
  }

  getNumberOfDueActivities() {
    return sumBy(
      filter(this['summary:activities'], { state: ACTIVITY_STATE__ACTIVE }),
      'total',
    );
  }

  getNumberOfUsers() {
    return this['summary:users'] || 0;
  }

  getWorkerMetadata(workerName) {
    const key = this.constructor.getWorkerMetadataKey(workerName);
    return this[key] || {};
  }

  static getWorkerMetadataKey(workerName) {
    return `_worker:${strToBase64Url(workerName)}`;
  }

  static getDefaultTimezone() {
    return defaultTimezone;
  }

  static getMomentInTimezone(
    timezone = this.getDefaultTimezone(),
    timestamp = Date.now(),
  ) {
    const offset = zoneToUtcOffset(timezone)(timestamp);
    if (isNaN(offset)) {
      return moment.invalid();
    }
    return moment(timestamp).utcOffset(offset);
  }
}

Project.scopeName = '@project';
Project.collection = 'Projects';

export default Project;
