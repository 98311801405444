import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Stack from '../primitives/Stack';
import Cluster from '../primitives/Cluster';
import Select, { StyledSelect } from '../Select';
import Button from '../Button';
import Pager from './Pager';
import { MAX_NUMBER_OF_PAGES } from '../../constants/ui';
import getPagesRange from './getPagesRange';

// TODO: Add size prop for pagination, for now default is "small"

const Pagination = ({
  pageSize,
  showSizeChanger,
  pageSizeOptions,
  onShowSizeChange,
  current,
  total,
  onChange,
}) => {
  const { t, i18n } = useTranslation();

  const languageDirection = i18n.dir();
  const isLanguageDirectionLTR = languageDirection === 'ltr';

  const handleSizeChange = (size) => onShowSizeChange('current', size);
  const handlePageChange = (page) => onChange(page);
  const handlePrevPage = () => onChange(current - 1);
  const handleNextPage = () => onChange(current + 1);
  const shouldDisablePreviousPageSelection = current <= 1;
  const shouldDisableNextPageSelection = total / pageSize === current;
  const pagesRange = getPagesRange(
    current,
    Math.ceil(total / pageSize),
    MAX_NUMBER_OF_PAGES,
  );

  return (
    <Cluster space={2}>
      <Stack>
        <Cluster space={2}>
          <Button
            size="small"
            type="link"
            icon={isLanguageDirectionLTR ? <LeftOutlined /> : <RightOutlined />}
            onClick={handlePrevPage}
            disabled={shouldDisablePreviousPageSelection}
          />
          <Cluster as="ul" space={2}>
            {pagesRange.map((page) => {
              return (
                <Pager
                  key={page}
                  page={page}
                  current={current}
                  onChange={handlePageChange}
                />
              );
            })}
          </Cluster>
          <Button
            size="small"
            type="link"
            icon={isLanguageDirectionLTR ? <RightOutlined /> : <LeftOutlined />}
            onClick={handleNextPage}
            disabled={shouldDisableNextPageSelection}
          />
        </Cluster>
      </Stack>
      {/* Wrap in a <div> until we use antd select */}
      {showSizeChanger && (
        <div>
          <StyledSelect
            size="small"
            value={pageSize}
            onChange={handleSizeChange}
          >
            {pageSizeOptions.map((value) => (
              <Select.Option key={value} value={Number(value)}>
                {value}
                {` / ${t('page').toLowerCase()}`}
              </Select.Option>
            ))}
          </StyledSelect>
        </div>
      )}
    </Cluster>
  );
};

Pagination.propTypes = {
  pageSize: PropTypes.number,
  current: PropTypes.number,
  total: PropTypes.number,
  showSizeChanger: PropTypes.bool,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onShowSizeChange: PropTypes.func,
};

Pagination.defaultProps = {
  pageSize: 10,
  current: null,
  total: 0,
  showSizeChanger: false,
  pageSizeOptions: ['10', '20', '30', '40'],
  onChange: () => {},
  onShowSizeChange: () => {},
};

export default Pagination;
