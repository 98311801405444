import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import max from 'lodash/max';
import min from 'lodash/min';
import compact from 'lodash/compact';
import omit from 'lodash/omit';
import { useDDPCall, useDDPSubscription } from '@theclinician/ddp-connector';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import ChartDataProviderSelect from '../../../../common/selectors/ChartDataProvider';
import ProjectDashboardSelect from '../../../../common/selectors/ProjectDashboard';
import ChartModel from '../../../../common/models/Chart';
import {
  apiAnalyticsDataProvidersForDashboard,
  apiAnalyticsDataProvidersForDashboardForceRefresh,
} from '../../../../common/api/analytics';
import Stack from '../../../../common/components/primitives/Stack';
import ChartGrid from '../../../../common/components/Chart/Grid';
import ConnectedFilters from '../DashboardAnalytics/ConnectedFilters';
import { FILTER_TYPE__PROPERTY } from '../../../../common/constants';
import Chart from './Chart';
import { createGetFilters } from './store';
import { notifyError } from '../../../../utils/notify';

// NOTE: Even though this uses the same data source as DashboardAnalytics,
//       we still need to have separate component for ChartBuilder charts
//       because chart.settings and overlay graphs are handled differently
//       in each case. In the future these "legacy" charts will be
//       transformed into analytics charts at Project Wizard level,
//       in which case we will no longer need to maintain a separate
//       dashboard component.
const DashboardChartBuilder = ({ dashboardId, recipientId, projectId }) => {
  const rawFilters = useSelector(
    createGetFilters(`dashboards.${dashboardId}.filters`),
  );
  const filters = useMemo(
    () => map(rawFilters, (filter) => omit(filter, 'meta')),
    [rawFilters],
  );
  const { id: subscriptionId, ready: subscriptionReady } = useDDPSubscription(
    apiAnalyticsDataProvidersForDashboard.withParams({
      projectId,
      dashboardId,
      controlId: '$meta.id',
      filters,
    }),
  );
  const { id: recipientSubscriptionId, ready: recipientSubscriptionReady } =
    useDDPSubscription(
      recipientId &&
        apiAnalyticsDataProvidersForDashboard.withParams({
          projectId,
          dashboardId,
          controlId: '$meta.id',
          filters: [
            {
              type: FILTER_TYPE__PROPERTY,
              settings: {
                id: 'recipientId',
              },
              state: {
                include: [recipientId],
              },
            },
          ],
        }),
    );
  const dashboard = useSelector(
    ProjectDashboardSelect.one().whereIdEquals(dashboardId),
  );
  const dataProviderStatus = useSelector(
    createSelector(
      ChartDataProviderSelect.all().where({
        controlId: subscriptionId,
      }),
      (allDataProviders) => {
        const lastChangedAt = max(
          compact(map(allDataProviders, 'lastChangedAt')),
        );
        const lastUpdatedAt = min(
          compact(map(allDataProviders, 'lastUpdatedAt')),
        );
        return {
          lastChangedAt,
          lastUpdatedAt,
        };
      },
    ),
  );

  const { ddpCall, ddpIsPending } = useDDPCall();

  const handleOnRefresh = useCallback(() => {
    if (!ddpIsPending) {
      ddpCall(
        apiAnalyticsDataProvidersForDashboardForceRefresh.withParams({
          projectId,
          dashboardId,
        }),
      ).catch(notifyError());
    }
  }, [ddpCall, ddpIsPending, projectId, dashboardId]);

  return (
    <Stack>
      <ConnectedFilters
        storeKey={`dashboards.${dashboardId}`}
        projectId={projectId}
        dashboardId={dashboardId}
        onRefresh={handleOnRefresh}
        refreshNeeded={
          dataProviderStatus.lastUpdatedAt &&
          dataProviderStatus.lastChangedAt &&
          dataProviderStatus.lastUpdatedAt < dataProviderStatus.lastChangedAt
        }
      />
      <ChartGrid>
        {map(dashboard && dashboard.cards, (card) => {
          const chart = ChartModel.fromCard(card);
          const chartId = card.id;
          return (
            <Chart
              loading={!(subscriptionReady && recipientSubscriptionReady)}
              key={chartId}
              chart={chart}
              chartDataProviderId={`${apiAnalyticsDataProvidersForDashboard.getName()}.${subscriptionId}.${chartId}`}
              recipientChartDataProviderId={`${apiAnalyticsDataProvidersForDashboard.getName()}.${recipientSubscriptionId}.${chartId}`}
            />
          );
        })}
      </ChartGrid>
    </Stack>
  );
};

DashboardChartBuilder.propTypes = {
  projectId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
  recipientId: PropTypes.string,
};

DashboardChartBuilder.defaultProps = {
  recipientId: null,
};

export default DashboardChartBuilder;
