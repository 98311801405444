import { useMemo } from 'react';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import every from 'lodash/every';
import some from 'lodash/some';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDDPSubscription } from '@theclinician/ddp-connector';
import { apiZedocOneProject } from '../common/api/zedoc';
import Project from '../common/models/Project';
import ProjectSelect from '../common/selectors/Project';
import {
  FILTER_TYPE__VARIABLE,
  FILTER_CONDITION__EQUALS,
  FILTER_CONDITION__NOT_EQUAL,
  FILTER_CONDITION__MINIMUM,
  FILTER_CONDITION__EXCLUSIVE_MINIMUM,
  FILTER_CONDITION__MAXIMUM,
  FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
  FILTER_CONDITION__TEXT,
  FILTER_CONDITION__INCLUDE,
  FILTER_CONDITION__EXCLUDE,
  FILTER_CONDITION__EXISTS,
  FILTER_CONDITION__DOES_NOT_EXIST,
  FILTER_CONDITION__DATE_EQUALS,
  FILTER_CONDITION__DATE_NOT_EQUAL,
  FILTER_CONDITION__DATE_AFTER,
  FILTER_CONDITION__DATE_BEFORE,
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
  YEAR_MONTH_DAY,
} from '../common/constants';
import useProjectVariables from './useProjectVariables';

const isArraySchema = (schema) => {
  if (!schema) {
    return false;
  }
  return schema.type === 'array';
};

const isTypeOrArrayOfType = (isType, schema) => {
  if (isArraySchema(schema)) {
    return isType(schema.items);
  }
  return isType(schema);
};

const isEnum = (schema) => {
  if (isArray(schema)) {
    return every(schema, isEnum);
  }
  if (!schema) {
    return false;
  }
  return (
    !!schema.enum ||
    !!schema.const ||
    isEnum(schema.anyOf) ||
    isEnum(schema.oneOf) ||
    some(schema.allOf, isEnum)
  );
};

const isDate = (schema) => {
  if (!schema) {
    return false;
  }
  return (
    schema.type === 'string' &&
    (schema.format === 'date' ||
      schema.format === 'partial-date' ||
      schema.format === 'year')
  );
};

const isFullDate = (schema) => {
  if (!schema) {
    return false;
  }
  return schema.type === 'string' && schema.format === 'date';
};

const isNumber = (schema) => {
  if (!schema) {
    return false;
  }
  return schema.type === 'integer' || schema.type === 'number';
};

const isString = (schema) => {
  if (!schema) {
    return false;
  }
  return schema.type === 'string';
};

export const makePreset = (variableId, name, jsonSchema) => {
  let condition;
  let conditions;
  let valueType;

  if (isTypeOrArrayOfType(isFullDate, jsonSchema)) {
    valueType = 'string';
    condition = FILTER_CONDITION__DATE_EQUALS;
    conditions = [
      FILTER_CONDITION__DATE_EQUALS,
      FILTER_CONDITION__DATE_NOT_EQUAL,
      FILTER_CONDITION__DATE_SAME_OR_AFTER,
      FILTER_CONDITION__DATE_SAME_OR_BEFORE,
      FILTER_CONDITION__DATE_AFTER,
      FILTER_CONDITION__DATE_BEFORE,
    ];
  } else if (isTypeOrArrayOfType(isDate, jsonSchema)) {
    // NOTE: It's not a full date, so it's a partial date.
    //       Because of that we use different set of conditions.
    valueType = 'string';
    condition = FILTER_CONDITION__MINIMUM;
    conditions = [
      FILTER_CONDITION__MINIMUM,
      FILTER_CONDITION__EXCLUSIVE_MINIMUM,
      FILTER_CONDITION__MAXIMUM,
      FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
      FILTER_CONDITION__EQUALS,
      FILTER_CONDITION__NOT_EQUAL,
    ];
  } else if (isTypeOrArrayOfType(isNumber, jsonSchema)) {
    valueType = 'number';
    condition = FILTER_CONDITION__MINIMUM;
    conditions = [
      FILTER_CONDITION__MINIMUM,
      FILTER_CONDITION__EXCLUSIVE_MINIMUM,
      FILTER_CONDITION__MAXIMUM,
      FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
      FILTER_CONDITION__EQUALS,
      FILTER_CONDITION__NOT_EQUAL,
    ];
  } else if (isTypeOrArrayOfType(isEnum, jsonSchema)) {
    valueType = isArraySchema(jsonSchema) ? 'array' : undefined;
    condition = FILTER_CONDITION__INCLUDE;
    conditions = [
      FILTER_CONDITION__INCLUDE,
      FILTER_CONDITION__EXCLUDE,
      FILTER_CONDITION__EXISTS,
      FILTER_CONDITION__DOES_NOT_EXIST,
    ];
  } else if (isTypeOrArrayOfType(isString, jsonSchema)) {
    valueType = isArraySchema(jsonSchema) ? 'array' : undefined;
    condition = FILTER_CONDITION__TEXT;
    conditions = [
      FILTER_CONDITION__TEXT,
      FILTER_CONDITION__INCLUDE,
      FILTER_CONDITION__EXCLUDE,
      FILTER_CONDITION__EXISTS,
      FILTER_CONDITION__DOES_NOT_EXIST,
    ];
  } else {
    condition = FILTER_CONDITION__EXISTS;
    conditions = [FILTER_CONDITION__EXISTS, FILTER_CONDITION__DOES_NOT_EXIST];
  }

  return {
    name,
    type: FILTER_TYPE__VARIABLE,
    condition,
    settings: {
      id: variableId,
      valueType,
      namespace: 'variables',
    },
    meta: {
      conditions,
      jsonSchema,
    },
  };
};

const useProjectFilters = ({ projectId, defaultFilters, allowedScopes }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { ready: projectReady } = useDDPSubscription(
    projectId &&
      apiZedocOneProject.withParams({
        projectId,
      }),
  );
  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const variables = useProjectVariables(projectId, allowedScopes);
  const timezone = project && project.getTimezone();
  const empty = useMemo(() => [], []);

  const presets = useMemo(() => {
    const allPresets = [...defaultFilters];
    forEach(variables, (variable) => {
      const jsonSchema = variable.getJsonSchema({
        language,
        projectId,
      });
      const name = variable.getTitle(language) || variable.name;
      const preset = makePreset(variable._id, name, jsonSchema);
      if (preset) {
        allPresets.push(preset);
      }
    });
    return map(allPresets, (preset) => {
      switch (preset.condition) {
        case FILTER_CONDITION__DATE_EQUALS:
        case FILTER_CONDITION__DATE_NOT_EQUAL:
        case FILTER_CONDITION__DATE_AFTER:
        case FILTER_CONDITION__DATE_BEFORE:
        case FILTER_CONDITION__DATE_SAME_OR_AFTER:
        case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
          return {
            ...preset,
            state: {
              ...preset.state,
              timezone,
              threshold:
                Project.getMomentInTimezone(timezone).format(YEAR_MONTH_DAY),
            },
          };
        default:
          return preset;
      }
    });
  }, [projectId, timezone, variables, defaultFilters, language]);

  if (!projectReady) {
    return empty;
  }

  return presets;
};

export default useProjectFilters;
